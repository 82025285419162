import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FilesDownloadComponent } from '../../download/component/files-download/files-download.component';

@Component({
  selector: 'app-files-download-dialog',
  templateUrl: './files-download-dialog.component.html',
  styleUrls: ['./files-download-dialog.component.scss'],
  standalone: true,
  imports: [MatIcon, MatDialogClose, MatDialogContent, FilesDownloadComponent],
})
export class FilesDownloadDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { files: Array<string>; title: string }) {}
}
